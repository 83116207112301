'use client'; // Error components must be Client Components

import { Box, Button, ClientGate, Colors, EpisodeThemeProvider, FixedBottom, Typography } from '@episode-ui';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import nativeutil from '@eppay/utils/nativeutil';
import { ErrorRoundedRed56Icon } from 'public/icons';
import * as Sentry from '@sentry/nextjs';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const router = useRouter();

  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
    console.log(error);
  }, [error]);

  const handleSubmitButtonClick = () => {
    nativeutil.back(router);
  };

  return (
    <ClientGate>
      <EpisodeThemeProvider>
        <Box display={'flex'} flexDirection={'column'} height={'100vh'} bgcolor={Colors.ep_white} alignItems={'center'}>
          <Box marginTop={'120px'} alignItems={'center'} justifyContent={'center'}>
            <ErrorRoundedRed56Icon />
          </Box>
          <Box padding={'8px'} alignItems={'center'}>
            <Typography variant={'h3'} color={Colors.ep_black}>
              서비스 오류 (500)
            </Typography>
          </Box>
          <Box padding={'8px'} alignItems={'center'}>
            <Typography variant={'body2'} whiteSpace={'pre'} textAlign={'center'} color={Colors.ep40}>
              서비스 이용에 불편을 드려 죄송합니다.
              <br />
              요청하신 페이지에 오류가 있습니다.
              <br />
              다시 한번 확인주세요.
              <br />
            </Typography>
          </Box>
          <FixedBottom>
            <Button fullWidth size="lg" onClick={() => handleSubmitButtonClick()}>
              <Typography variant="body1_bold">확인</Typography>
            </Button>
          </FixedBottom>
        </Box>
      </EpisodeThemeProvider>
    </ClientGate>
  );
}
