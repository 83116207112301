import { NextRouter } from 'next/router';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

export interface EppayJSInterface {
  postMessage: (message: string) => void;
}

declare const eppayWebChange: EppayJSInterface | undefined;
declare const biometricStorage: EppayJSInterface | undefined;

export default {
  moveToAlarmPage: () => {
    if (typeof eppayWebChange !== 'undefined') {
      eppayWebChange.postMessage('alarmPage');
      return true;
    }
    return false;
  },
  hideBottomNavigationBar: () => {
    if (typeof eppayWebChange !== 'undefined') {
      eppayWebChange.postMessage('hide');
      return true;
    }
    return false;
  },
  showBottomNavigationBar: () => {
    if (typeof eppayWebChange !== 'undefined') {
      eppayWebChange.postMessage('show');
      return true;
    }
    return false;
  },
  closeWebView: () => {
    if (typeof eppayWebChange !== 'undefined') {
      eppayWebChange.postMessage('close');
      return true;
    }
    return false;
  },

  back: (router: AppRouterInstance) => {
    // if (history.length > 1) {
    //   router.back();
    //   return true;
    // } else {
    //   if (typeof epverseWebChange !== "undefined") {
    //     epverseWebChange.postMessage("close");
    //     return true;
    //   }
    //   return false;
    // }
    if (typeof eppayWebChange !== 'undefined') {
      eppayWebChange.postMessage('back');
      // return true;
    } else {
      router.back();
      // return false;
    }
  },
  reToken: () => {
    if (typeof eppayWebChange !== 'undefined') {
      eppayWebChange.postMessage('reToken');
      return true;
    }
    return false;
  },
  getBioAuth: () => {
    if (typeof biometricStorage !== 'undefined') {
      biometricStorage.postMessage(`loadData`);
      return true;
    }
    return false;
  },
  loadBioAuthData: () => {
    if (typeof biometricStorage !== 'undefined') {
      return biometricStorage.postMessage(`loadData`);
    } else{
      return '';
    }
  },
  saveBioAuth: (passwd?: string) => {
    if (typeof biometricStorage !== 'undefined') {
      biometricStorage.postMessage(`saveData:${passwd}`);
      return true;
    }
    return false;
  },
  enablePullToRefresh: () => {
    if (typeof eppayWebChange !== 'undefined') {
      eppayWebChange.postMessage('enablePullToRefresh');
      return true;
    }
    return false;
  },
  disablePullToRefresh: () => {
    if (typeof eppayWebChange !== 'undefined') {
      eppayWebChange.postMessage('disablePullToRefresh');
      return true;
    }
    return false;
  },
};
